<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <v-expansion-panels class="mb-4">
            <v-expansion-panel>
              <v-expansion-panel-header> Фильтры </v-expansion-panel-header>
              <v-expansion-panel-content>
                <element-group-list-filter />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <custom-header :title="pageTitle">
            <create-element-button @saved="loadItems" />
          </custom-header>

          <in-publications-list
            :items="filterItems"
            :filtered="filtered"
            url-name="publications-detail"
            @saved="loadItems"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SET_BREADCRUMB,
  SET_BREADCRUMB_SHOW_BACK,
} from '@/core/services/store/breadcrumbs.module';
import { GET_ITEMS } from '@/core/services/store/publications.module';
import { mapState } from 'vuex';
import InPublicationsList from '@/view/content/lists/InPublicationsList';
import ElementGroupListFilter from '@/view/content/filters/ElementGroupListFilter';
import FilteredRouteItemsMixin from '@/view/content/filters/FilteredRouteItemsMixin';
import CreateElementButton from '@/view/content/widgets/publications/CreateElementButton.vue';
import { GET_ITEMS as GET_ITEMS_ACTIONS } from '@/core/services/store/actions.module';

export default {
  components: {
    InPublicationsList,
    ElementGroupListFilter,
    CreateElementButton,
  },

  mixins: [FilteredRouteItemsMixin],

  data() {
    return {
      pageTitle: '',
    };
  },

  computed: {
    ...mapState({
      items: (state) => state.publications.items,
    }),
  },

  async mounted() {
    await this.$store.dispatch(GET_ITEMS_ACTIONS);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Публикации', route: { name: 'general-publications' } },
      { title: 'Рабочий стол' },
    ]);
    this.$store.commit(SET_BREADCRUMB_SHOW_BACK, false);
    this.loadItems();
  },

  methods: {
    async loadItems() {
      const result = await this.$store.dispatch(
        GET_ITEMS,
        this.$route.params.id
      );
      if (result) {
        this.pageTitle = result.data.title;
      }
    },
  },
};
</script>
