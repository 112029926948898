<template>
  <div class="create-block-buttons block-element">
    <b-button variant="success" @click="modalShow = true"> Создать </b-button>
    <b-modal v-model="modalShow" scrollable size="xl" @ok="saveForm">
      <template #modal-title>
        <h5>Создание блока</h5>
      </template>

      <publications-parent-form
        v-model="createItem"
        ref="publicationsCreateParentForm"
        @saved="$emit('saved')"
      />

      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="saveForm">
          Создать
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PublicationsParentForm from '@/view/content/forms/publications/PublicationsParentForm.vue';

export default {
  components: {
    PublicationsParentForm,
  },

  data() {
    return {
      modalShow: false,
      createItem: {
        Name: '',
        Notes: '',
        is_active: true,
        items: [],
        ElementTypeID: 1,
        ElementTypeCode: '',
        ElementTypeName: '',
        color: '',
        params: '{}',
        SortOrder: 0,
        Code: '',
      },
    };
  },

  methods: {
    async saveForm() {
      const result = await this.$refs?.publicationsCreateParentForm?.submit();

      if (result) {
        this.modalShow = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
